.file-uploader-outside-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.file-uploader-outside-wrapper > h3{
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 34px;
    color: #000;
    margin: 0px 0px 20px 5%;
}
.file-uploader-container {
    width: 90%;
    height: 500px;
    min-height: 500px;
    margin: auto;
    background-color: #FFF;
    border: dashed 4px #9F9F9F;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 15px;
}

.resumable-drag-over {
    padding: 1rem;
    border: dashed 4px #000;
}

#resumable-drop-target, .resumable-progress-bar-container {
    width: 100%;
    height: 100%;
}

.resumable-drag-over #resumable-drop-target {
    opacity: 0.7;
}

.resumable-drag-over .rrr {
    visibility: hidden;
}
.file-uploader-container .phrase-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* width: 100%; */
}
.file-uploader-container .phrase-container .phrase,
.file-uploader-container .phrase-container .drag-over-help {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;
    color: #000;
    margin-bottom: 10px;
}

.drag-over-help {
    display: none;
    justify-content: center;
    align-items: center;
}

.resumable-drag-over .drag-over-help {
    display: flex;
    color: #000;
}

.resumable-progress-bar-container {
    height: 4rem;
    border-color: gray;
}

.resumable-progress-bar {
    background-color: gray;
    height: 100%;
}

#resumable-browse-button {
    background-color: #000;
    color: #FFF;
    border-radius: 8px;
    padding: 0.50rem 0.50rem;
}