.program-entry {
    border-bottom: 1px solid #e7e7e7;
}

.vhs-rest-day {
    background-color: whitesmoke;
    border: 1px dashed #e7e7e7;
    padding: 0 0.5rem;
    min-height: 4rem;
    line-height: 4rem;
    text-align: center;
}

.items-baseline {
    align-items: baseline;
}

.delete-button {
    align-items: center;
}