@tailwind base;
@tailwind components;
@tailwind utilities;

th {
    word-break: normal;
}
h1 {
    font-size: 2em;
    font-weight: 700;
}

.highlighted-tr {
    background-color: #f4fbef;
}

main {
    height: 100vh;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

select {
    padding: 0.5rem;
    border-radius: 4px;
    border: solid 1px lightgray;
}

.edit-table select, .edit-table input, .edit-table textarea{
    background-color: #f5f5f5;
    border:none;
}

input {
    padding: 0.5rem;
    border-radius: 8px;
    background-color: #F5F5F5;
    display: block;
    width: 90%;
}

textarea {
    padding: 0.5rem;
    border-radius: 4px;
    border: solid 1px lightgray;
    display: block;
    width: 90%;
    height: 5em;
}

 a, .selectable-field {
    font-weight: 500;
    color: var(--primary-dark-color);
    cursor: pointer;
}

.uppercase{
    text-transform: uppercase;
}

.link-black{
    color: #7A7A7A;
    font-weight: 300;
}

.link-button {
    background-color: var(--primary-dark-color);
    color: white;
    border-radius: 8px;
    padding: 0.5rem 1rem;
    display: inline-flex;
    align-items: center;
    margin-top: 0.5rem;
}

.bg-green-add {
    background-color: #3e8f1a;
    border-radius: 0.3rem;
}

.bg-light-section {
    background-color: #edf5f7;
}

.modal-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
}

.modal-container .modal-content {
    min-width: 400px;
    min-height: 400px;
    max-width: 98%;
    max-height: 98%;
    background-color: white;
    padding: 1rem;
    position: relative;
    border-radius: 15px;
}

.modal-container .modal-content .modal-header {
    display: flex;
}

.table-checkbox {
    display: flex;
    align-items: center;
    font-weight: 400;
}

.hide_in_languages-checkbox,
.table-checkbox > input[type='checkbox'] {
    width: auto !important;
    margin-right: 0.25rem !important;
    cursor: pointer;
}
textarea{
    height: 10em;
}

.table-image {
    max-height: 80px;
    max-width: 100%;
}
.clearSearchBox{
    right: 32px;
    top: 8px;background-color: #aeaeae;
    border-radius: 24px;
    height: 25px;
    width: 25px;
}
input[type='checkbox']{
    width: auto;
}
.multiselect{
    max-width: 40%;
    float: left;
    min-width: 40%;
}
.capitalize{
    text-transform: capitalize;
}

.content-in-row{
    display: flex;
    flex-direction: row;
    gap: 10px;
}
td{
    max-width: 300px;
}
td.tags {
    max-width: 11em;
    width: 11em;
    padding: 0;
}
div.tagbox {
    display: flex;
    flex-wrap: wrap;
    padding: 0.3em;
    overflow: auto;
    max-height: 5em;
}
div.tagbox .tag {
    float: left;
    border-radius: 16px;
    margin: 0 0.3em 0.3em 0;
    padding: 4px 8px;
    font-size: 12px;
    background: black;
    line-height: 1;
    color: white;
}

.content {
    background: white;
    border: 1px solid #E7E7E7;
}
.content a {
    color: var(--primary-dark-color);
}
.content-denied {
    background: #ebb;
}
.content-published {
    background: #bdb;
}
section.content-approving {
    flex-grow: 1;
}
section.content-creator {
    min-width: 15em;
}
section.content-actions {
    display: flex;
    flex-direction: column;
    min-width: 10em;
}
section.content-actions button {
    margin-bottom: 1em;
}
.content-header {
    text-transform: uppercase;
    font-size: 0.8em;
    font-weight: bold;
    color: #9F9F9F;
}
.content-parent {
    text-transform: uppercase;
    font-size: 0.8em;
    font-weight: bold;
    color: #9F9F9F;
}
.content-title {
    font-weight: bold;
}
.content-body{
    font-weight: 500;
}
.content-creator-avatar {
    width: 3em;
    border-radius: 1.5em;
    overflow: hidden;
    float: left;
    margin-top: 0.25em;
}
.content-creator-with-avatar {
    margin-left: 3.5em;
}
.content-creator-name {
    font-weight: bold;
}
.content-applied-button {
    background: #888;
    border-radius: 0.3rem;
}


  /* Refactored CSS */
body{
    --primary-color: #E8F0F2;
    --primary-dark-color: #488A98;
    --base-color: #F5F5F5;
    --bold-font: 'SF Pro Display Bold', sans-serif;
    --neutral-gray-color: #C5C5C5;
    --error-color: #FF5B37;
    font-family: 'SF Pro Display', sans-serif;
    background-color: var(--base-color);
}

@layer base {
    .bg-dark-primary {
        @apply bg-[var(--primary-dark-color)];
    }
    .text-primary-dark-color {
        @apply text-[var(--primary-dark-color)];
    }
}

.bg-dark-primary{
    background-color: var(--primary-dark-color);
}
.limited-text-overflow-10{
    overflow: hidden;
   display: -webkit-box;
   -webkit-line-clamp: 10; /* number of lines to show */
           line-clamp: 10; 
   -webkit-box-orient: vertical;
}

.text-gray{
    color: #757575;
}
.text-light-gray{
    color: var(--neutral-gray-color);
}

.button{
    border-radius: 8px;
    padding: 8px 14px;
    background-color: var(--primary-dark-color);
    color: var(--base-color);
}

@font-face {
    font-family: "SF Pro Display";
    src: local("SF Pro Display"), url("./fonts/SFPRODISPLAYMEDIUM.OTF") format("truetype");
}

@font-face {
    font-family: "SF Pro Display Bold";
    src: local("SF Pro Display Bold"), url("./fonts/SFPRODISPLAYBOLD.OTF") format("truetype");
    font-weight: bold;
}

.font-bold{
    font-family: var(--bold-font);
    font-weight: 700;
}

.routes-container {
    height: 100%;
}

.drawer-container {
    background-color: #fff;
    position: relative;
}

.drawer-container.open {
    width: 310px;
    min-width: 310px;
}

.drawer-container.close {
    width: 64px;
    min-width: 64px;
}

.open-menu {
    width: 24px;
    height: 24px;
}
.open-menu.toOpen {
    transform: rotate(180deg);
}

.menu-region-container {
    max-width: 100%;
}
.menu-region-container select{
    background: #E7E7E7;
    color: #000;
    padding: 4px 12px;
    border-radius: 45px;
    max-width: 100%;
}

.menu {
    font-size: 1.06em;
}

.menu li a{
    position: relative;
}

.menu li a.active, .menu li a.active:hover {
    background-color: var(--primary-color);
    color: var(--primary-dark-color);
}
.menu li a:hover{
    background-color: var(--base-color);
    color: var(--primary-dark-color);
}
.menu li>ul>li a{
    font-size: 0.92em;
}
.menu li>ul>li a.active, .menu li>ul>li a.active:hover {
    background-color: var(--base-color);
    color: var(--primary-dark-color);
}
.menu li a .arrow-btn{
    width: 25px;
    height: 25px;
    position: absolute;
    right: 8px;
    border-radius: 20px;
    padding: 2.5px 0 0 2.5px;
}
.menu li a .arrow-btn:hover{
    background-color: #fff;
}
.menu li a .chevron {
    width: 20px;
    height: 20px;
}

.menu .menu-icon{
    margin-right: 6px;
    width: 24px;
    height: 23px;
}
.logout-btn:hover{
    background-color: var(--primary-color);
}
.logout-btn .menu-icon{
    width: 24px;
    height: 23px;
}


.text-primary-dark-color {
    color: var(--primary-dark-color);
}

.footer-version{
    position:absolute;
    bottom: 0;
    right: 1em;
    font-size: 0.7em;
    color: #888;
    background-color: rgba(255, 255, 255, 0.4);
    padding: 4px 8px;
}

.breadcrumbs {
    font-size: 1.065em;
    color: #505050;
}
.breadcrumbs .active:last-of-type {
    color: var(--primary-dark-color);
    background-color: var(--primary-color);
}
.breadcrumbs .arrow {
    border: solid var(--neutral-gray-color);
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 3px;
    margin-right: 15px;
    margin-left: 10px;
    top: -1.5px;
    position: relative;
  }
  
  .breadcrumbs .arrow.right {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
  }

  .subsectionName{
    color: var(--primary-dark-color);
    font-size: 28px;
    font-family: var(--bold-font);
  }
.delete-button {
    background-color: var(--error-color);
    color: white;
    gap: 8px;
    display: flex;
    flex-direction:row;
    align-items:flex-end;
    white-space: nowrap;
    max-height: 42px;
}

.add-button {
    background-color: #000;
    color: white;
}

.disabled-button {
    background-color: #d3d3d3;
    cursor: not-allowed;
}

.delete-button img{
    width: 24px;
    height: 24px;
}

.filter-box{
    border-bottom: 1px;
    border-color: var(--neutral-gray-color);
    border-style: solid;
}

.filter {
    display: flex;
  align-items: center;
  height: 40px;
  justify-content: space-between;
  background-color: #E7E7E7;
  border-radius: 8px;
  border: 2px solid #e9e9e9;
}

.filter select, .filter button{
    background-color: transparent;
  border: none;
  padding: 10px;
  cursor: pointer;
  color: #000
}

.search-btn{
    min-width: none;
    background-color: transparent;
}
.search-icon{
    width: 24px;
    height: 24px;
}
.search-input{
    font-size: 18;
}

.filter-icon{
    width: 24px;
    height: 24px;
}

.extra-filters{
    display: none;
}

.alert-title{
    font-size: 20px;
}
.cancel-btn{
    background-color: var(--primary-dark-color);
}
.ok-btn{
    background-color: var(--primary-color);
    color: var(--primary-dark-color);
}
.ok-btn:hover, .cancel-btn:hover{
   opacity: 0.9;
}
.alert-modal-buttons-container > button{
    padding: 5px 10px;
    border-radius: 4px;
}

.tabs-list {
    border-radius: 8px;
}

.tabs-list li {
    color: #7A7A7A;
    padding: 8px 12px;
    cursor: pointer;
}

.tabs-list li.active{
    background-color: var(--primary-color);
    color: var(--primary-dark-color);
    border-radius: 8px;
}

table{
    border-collapse: collapse;
    border-radius: 8px;
    border-style: hidden;
    box-shadow: 0 0 0 1px #e7e7e7;
    background-color: white;
    width: 100%;
    table-layout: fixed;
}

.card-box {
    border-radius: 8px;
    background-color: white;
    border-color: #e7e7e7 ;
}

.card-box-bordered {
    border-radius: 8px;
    background-color: white;
    border: 1px solid #e7e7e7;
}

.edit-table{
    width: 75%;
}

th, td{
    border-color: #e7e7e7;
    border-width: 1px 0;
    padding: 18px 12px;
}


.edit-table th, .edit-table td{
    border: none
}


.edit-table td.label, .overview-table td.label{
    width: 170px;
    vertical-align: top;
    padding-top: 22px;
    padding-left: 20px;
}

.overview-table td.label {
    color: #9F9F9F;
}

.edit-table input, .edit-table select, .edit-table textarea, .edit-table .multiselect {
    width: 40%;
}

.card-rules tr {
    display: flex;
    align-items: center;
    height: 60px;
}

.card-rules td {
    padding-top: 0;
    padding-left: 0;
    min-width: 100px;
}

.w-full{
    width: 100% !important;
}

thead th, thead td{
    background-color: var(--primary-color);
    border: none;
    padding: 12px;
    font-size: 18px;
    text-align: left;
}


table.border-none{
    box-shadow: none;
}

.tab-nav .tab-nav-element {
    display: flex;
    align-items: center;
    padding: 10px 12px;
    cursor: pointer;
    border-width: 0 1px 0 0;
    border-style: solid;
    border-color: #e7e7e7;
}

.tab-nav .tab-nav-element:last-child {
    border: none;
}
.tab-nav .tab-nav-element.active {
    background-color: var(--primary-color);
    color: var(--primary-dark-color)
}

.member-subline{
    color: black;
    background-color: #F5F5F5;
}

.save-changes-button {
    float: right;
}

.rmsc .dropdown-container {
    background-color: #f5f5f5;
    border: none;
}

.rmsc .gray{
    color: #000;
}

.circle-button{
    border-radius: 24px;
    background-color: black;
    color: white;
    width: 30px;
    height: 30px;
}

.primary-color-box{
    background-color: var(--primary-dark-color);
    color: white;
}

.tags-container-border{
    border: 1px solid #E7E7E7;
}

.checkbox-label{
    font-family: 'SF Pro Display', sans-serif !important;
    cursor: pointer;
}