.App {
    display: 'flex';
    flex: 1;
}

.site-header {
    box-shadow: 0 3px 5px rgb(0 0 0 / 20%);
    z-index: 3;
    position: relative;
    background-color: #498a98;
}

.site-header .container {
    position: relative;
    height: 47px;
}

.site-header .logo {
    background-color: #fff;
    box-shadow: 0 3px 20px rgb(0 0 0 / 50%);
    padding: 10px;
    display: inline-block;
    position: relative;
    z-index: 1;
    position: absolute;
    left: 0;
    top: 0;
}

.header-menu-container ul {
    list-style: none;
    padding: 0;
    margin: 0;
    font-size: 0;
    text-align: right;
}

.site-header .logo img {
    height: 50px;
}




.container {
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

.drawer-separator {
    border-top: solid 1px #aaa;
}

th.email {
    word-break: break-word;
}
td.email {
    word-break: break-word;
}


h1.admin-section-title {
    font-size: 2em;
    font-weight: bold;
    margin-bottom: 1em;
}



.big-area {
    height: 20em;
}

.bg-emerald-600 {
    background-color: rgb(5 150 105);
}

.bg-gray-tags {
    background-color: rgb(230, 230, 230);
}

.blue-button-color {
    background-color: rgba(65, 138, 211, 255);
}

.order-button {
    background-color: #498a98;
    color: white;
    border-radius: 0.3rem;
    padding: 0.5rem 1rem;
}

.table-view-button {
    background-color: #2d9bf0;
    color: white;
    border-radius: 0.3rem;
    padding: 0.5rem 1rem;
}

.custom-button {
    display: flex;
    align-items: center;
    color: white;
    border-radius: 0.3rem;
    padding: 0.5rem 1rem;
}

.select-button {
    background-color: #9510ac;
    color: white;
    border-radius: 0.3rem;
    padding: 0.5rem 1rem;
    display: inline-flex;
    align-items: center;
    margin-top: 0.5rem;
}

.unassign-background {
    background-color: #498a98;
}

.make-poster-background {
    background-color: #0ca789;
}

.reencode-background {
    background-color: #9510ac;
}

.login-content {
    display: flex;
    justify-content: center;
    align-items: center;
}